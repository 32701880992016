import React,{useState,useEffect,useContext} from 'react';
import {Box,Typography,//IconButton,
    IconButton,Tooltip,FormControl,InputLabel,Select,
    MenuItem,TextField,FormControlLabel,Checkbox} from "@material-ui/core";
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
//import TablaDto from './TablaDto';
import Graficas from './GraficasRegion';
import CardTotal from './CardTotal';
import TablaMun from './TablaMun';
//import GraficaPolar from './GraficaPolar';
import IntervaloSelect from '../perfiles/IntervaloTabla';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import TablaTop from './TablaTop';
import MapaMarcador from './MapaMarcador';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import TablaSeccRecorrido from './TabSecRecor';
const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
"#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const AvanceRecorrido = () => {
    const history = useHistory();
    const ColBase=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const auxId =localStorage.getItem('UsuId') ;  
    const nvl= localStorage.getItem('Tipo');          
    const usu= localStorage.getItem('UsuarioNom');   
    const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 ? localStorage.getItem('Dispvo') :'w8rf51v21dsd2cs'      
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);    
    const [listTotl, setListTotl] = useState([])                   
    const [listAv, setListAv] = useState([])                
    const [espera, setEspera] = useState(false)       
    const [listDistrito, setListDistrito] = useState([])           
    const [registro, setRegistro] = useState({Id:0,Num:0})      
    const [regMun, setRegMun] = useState(null)            
    const [listAvMun, setListAvMun] = useState([])        
    const [listGralMap, setListGralMap] = useState([])        
    const [listGralOrg, setListGralOrg] = useState([])        
    const [mapRegis, setMapRegis] = useState(null)        
    const [long,setLong] = useState(-103.2993503);
    const [lat,setLat] = useState(23.5120348);
    const [zoom, setZoom] = useState( 3.7 );
    const [fSelect, setFSelect] = useState({inicio:new Date("2023-01-01T00:00:00"),final:new Date()});
    const [buscar, setBuscar] = useState(false);
    const [tipoTabla, setTipoTabla] = useState(2);
    const [listMapRecor, setListMapRecor] = useState([])    
    const [centro, setCentro] = useState({lat: 19.493815, lng: -99.1137317})     
    const [checkEtiqSecc, setCheckEtiqSecc] = useState(false);
    const [puente, setPuente] = useState([]);

    const reiniciar=()=>{
        setTipoTabla(1)
        setListTotl([])                   
        setListAv([])                                
        setRegistro({Id:0,Num:0})      
        setRegMun(null)            
        setListAvMun([])        
        setListGralMap([])                
        setMapRegis(null)        
        setLong(-103.2993503);
        setLat(23.5120348);
        setZoom( 3.7 );        
        setBuscar(!buscar);
    }

    useEffect(()=>{             
        const autenticado = authUser();
        if(!autenticado)
        {   localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {   if (usuario.length===0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                           
            }                       
            let auxArregloColor=[], auxArregloColor2=[]
            if (125 > arreColores.length) 
            {    
                for (let index = 0; index < 125; index++) {                    
                    const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                    auxArregloColor.push({pos:index + 1 ,auxColor:arreColores[nivel]})      
                }                         
                /* aux.forEach((element,index )=> {
                    const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                    auxArregloColor.push({pos:element.Id,auxColor:arreColores[nivel]})      
                }); */
                for (let index = 0; index < arreColores.length; index++) 
                {
                    let auxPos=[]
                    const filtrado=auxArregloColor.filter((color)=>color.auxColor===arreColores[index] )  
                    filtrado.forEach((color,indice)=>{                        
                        auxPos[indice] = `${color.pos}`
                    })              
                    auxArregloColor2.push({pos:auxPos,auxColor:arreColores[index]})      
                }
                //console.log(auxArregloColor2);    
                setListGralMap(auxArregloColor2)
                setListGralOrg(auxArregloColor2)
            }  
            guardarGenerales({...generales,Titulo:'Avance General'})      
            llenaTotales(1,0)            
          //  llenaListDistr()
        }
        
        return ()=> {
            source.cancel();
        }      
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[buscar]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {            
            if (registro!==null &&  registro?.Id!==0) 
            {
                //console.log(registro);
                llenaTotales(2,registro.Id)

                //const filtro =listTotl[0].estado.filter((edo)=>edo.EdoId===registro.Id)
                //setListAv(filtro)             

                 let editable=listGralOrg.filter(obtenPos )
                if (editable.length!==0) 
                {   let nvo=[]
                    listGralOrg.forEach(element => {
                        let aux=element
                        if (Array.isArray(element.pos) && element.pos.length === editable[0].pos.length && element.pos === editable[0].pos) 
                        {   let nvoPos=[]
                            for (let index = 0; index < element.pos.length; index++) {
                                
                                if (element.pos[index] === `${registro.Id}`) {                                
                                    nvo.push({pos:[`${registro.Id}`],auxColor:ColBase})
                                }else{
                                    nvoPos.push(element.pos[index])
                                }
                                
                            }
                            if (element.pos === editable[0].pos) {
                                aux={pos:element.pos,auxColor:ColBase}
                            }  
                         
                            aux={pos:nvoPos,auxColor:element.auxColor}
                           // console.log(aux);
                             
                        }
                        else
                        {   if (typeof element.pos === 'string' || element.pos instanceof String)
                            {   if (element.pos === `${registro.Id}`) 
                                {   aux={pos:[`${registro.Id}`],auxColor:ColBase}
                                }
                            }
                        }
                        
                        nvo.push(aux)
                    });
                   //console.log(nvo);
                    //setListGralMap(nvo)
                }  
             const coord=listTotl[0].estado.filter((dist)=>dist.EdoId ===registro.Id)
                if (coord.length!==0) {
                    setLong(coord[0].Lon) 
                    setLat(coord[0].Lat)
                    setZoom(5)
                }  
               //  console.log(coord);
            }else{
                //setListGralMap(listGralOrg)
                setListAv(listTotl[0].avc_gral)  
                setListAvMun([])
                setRegMun(null)
                setLong(-103.2993503) 
                setLat(23.5120348)
                setZoom( 3.7 )
            }    
        }
        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[registro]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {
            if (mapRegis!==null  ) 
            {   setRegistro({Id:parseInt(mapRegis.CVEGEO),Num:mapRegis.NOMGEO})
                setTipoTabla(2)
               // llenaTotales(2,mapRegis.DTO_ID)
             
            } 
        }
        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mapRegis]);

    function obtenPos(num) {
        let bandera=false
        //console.log(num);
        for (let i = 0; i<num.pos.length ; i++) {
            const pos=num.pos[i]
          //  console.log(pos);
          if (pos=== `${registro.Id}`) {
            bandera=true;
          }
        }
        return bandera;
    }    

    const llenaTotales = (auxTipo,auxEdo) => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");         
        const data = qs.stringify({
            usuario: auxId,                             
            status:0,
            estado:9,
            municipio:138,
            seccion:0,
            otro:0,
            fini:fInicio+'T00:00:00',
            ffin:fFinal+'T23:59:59',
            idDispositivo: auxDispV,
        });
        const url = "recorrido/v2/avance";

        function respuesta(response) {
            if (response[0].Id !== -1) 
            {  //console.log(response);
                if (auxTipo===1) 
                {
                    setListAv(response[0].total)   
                    setListTotl(response) 
                    setTipoTabla(1)
                }else{
                    if (auxTipo===2) 
                    {
                        setListAvMun(response)
                        
                      //  console.log(response);
                    }
                }                                           
            }
        }
    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral,setEspera, history, source);
    };

    const getListRecor = (seccion) => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");       
        setRegMun(seccion)  
        const data = qs.stringify({
            usuario: auxId,                                         
            seccion:seccion.SecId,            
            fini:fInicio+'T00:00:00',
            ffin:fFinal+'T23:59:59',
            idDispositivo: auxDispV,
        });
        const url = "recorrido/v2/seccion/avance";

        function respuesta(response) {
            if (response[0].Id !== -1) 
            {   
                setListMapRecor(response)    
                setCentro({lat: response[0].Lat, lng: response[0].Lon})             
            }                                          
        }
        
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral,setEspera, history, source);
    };

    return (
    <div style={{display:"flex"}}>
        
    <Box>
        <Box display={'flex'} pb={1}>
            <Box >
                <IconButton color="primary"  aria-label="add circle" 
                component="span" onClick={()=>setBuscar(!buscar)}>
                    <Tooltip title="Buscar">
                        <SearchIcon/>
                    </Tooltip>
                </IconButton>                                                                                                                              
            </Box>
            <Box >
                <IconButton color="primary"  aria-label="add circle" 
                component="span" onClick={reiniciar}>
                    <Tooltip title="Reiniciar">
                        <ReplayIcon/>
                    </Tooltip>
                </IconButton>                                                                                                                              
            </Box>
            <Box >
                <IntervaloSelect  fSelect={fSelect}  setFSelect={setFSelect}/>
            </Box>
            {listAv.length !== 0 ?
                <CardTotal valor={listAv[0]} dtoId={registro?.Id} />
            :null
            }
        </Box>
        <Box display={"flex"}>
        {/* listAv.length !== 0 ? 
            <Graficas valor={listAv} />
        :null */ }
        {listTotl.length !== 0 ? 
            <TablaTop listTop={listTotl[0]} />
        :null }
        </Box>
        <Box display={"flex"} flexDirection={"row"} mt={1}>
        
            { listAvMun.length !== 0 || listTotl.length !== 0 ?
                <TablaMun  listAvMun={listAvMun} registro={regMun} registroEdo={registro} 
                    setRegistro={getListRecor} tipoTabla={tipoTabla} 
                    listAvEdo={listTotl}
                />       
            :null}
            {regMun !== null && listMapRecor.length !== 0 ?
            <Box display="flex" flexDirection={"column"} style={{width:"38rem",height:"20rem"}}>
                <Box display="flex">
                    <Typography>{`${regMun.SecNum} Sección`}</Typography>
                    {/* <Box display="flex"  alignItems="center"  ml={2}>
                        <Typography variant="body1">Barda</Typography>
                        <Brightness1Icon fontSize="small" style={{color: "#58b85c"}} />
                    </Box>
                    <Box display="flex"  alignItems="center" ml={2}>
                        <Typography variant="body1">Lona</Typography>
                        <Brightness1Icon fontSize="small" style={{color: "#af272f"}} />
                    </Box>
                    <Box display="flex" alignItems="center" ml={2} >
                        <Typography variant="body1">Especialidad</Typography>           
                        <Brightness1Icon fontSize="small" style={{color: "#4295f5"}} />
                    </Box> */}
                    <Box display="flex" alignItems="center" ml={2} >
                        <Typography  variant="body1">Bache</Typography>
                        {/* <Brightness1Icon fontSize="small" style={{color: "#182ac9"}} /> */}
                        <img src='https://benitojuarez.sfo3.cdn.digitaloceanspaces.com/recurso/marker123.svg' style={{width:"20px",height:"20px"}}/>
                    </Box>
                </Box>
            
                <Box width={"100%"}>
                    <MapaMarcador poligonos={[]}  centro={centro} 
                        setCambiaMarcador={()=>{}}     zoom={12.5}  auxTam={{wd:600, hgt:400}}                               
                        listMapProm={[]} listApoyos={[]} listRecorrido={listMapRecor}
                        setCheckEtiqSecc={setCheckEtiqSecc}
                        puente={puente} setPuente={setPuente}
                    />
                </Box>                
            </Box>
            :null}
            {regMun !== null && listMapRecor.length !== 0 ? 
            <Box>
                <TablaSeccRecorrido listMapRecor={listMapRecor} 
                    puente={puente} setPuente={setPuente}
                />
            </Box>
            :null}
        </Box>            
    </Box>
        
    </div>
  )
}

export default AvanceRecorrido