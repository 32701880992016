import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Box, InputLabel, LinearProgress, MenuItem, Select, TextField,
    RadioGroup, Radio, FormControlLabel, FormLabel
} from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import SelectEdo from '../generales/SelectEdo';

const useStyles = makeStyles((theme) => ({}));

const NvoComercio = ({abrirModal, setAbrirModal, idusu, history, source, latitud, longitud,actualiza}) => {

    const classes = useStyles();
    let usuTipo = localStorage.getItem('Tipo');
    let fecha = Date.now()
    const [selectedDate, handleDateChange] = useState(fecha);
    const [listMun, setListMun] = useState([])
    const [listSecc, setListSecc] = useState([])
    const [auxListSecc, setAuxListSecc] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [listHorario, setListHorario] = useState([])
    const [listCP, setListCP] = useState([])
    const [listColonia, setListColonia] = useState([])
    const [listGiro, setListGiro] = useState([])
    const [nuevoRegistro, setNuevoRegistro] = useState({
        Estado: process.env.REACT_APP_EDO,
        Municipio: 138, Seccion: '', Tipo: '', Latitud: latitud,
        Longitud: longitud, Fecha: '', Direccion: '', Observacion: '',
        Giro:"", Nombre:"", Alias:"", Calle:"", NoExt:"",
        NoInt:"", Colonia:"", Cp:"", Manual:0, ManualDir:"",
        Cantidad:0, Documentacion:"", Regulado:"", ReguladoAnio:0, Afiliado:0,
        AfiliadoDescr:"", Telefono:"", Contacto:"", Tipo:"", Horario:"", Largo:0,
        Ancho:0,
    });
    const {Estado, Municipio, Seccion, Tipo, Longitud, Latitud, Fecha, Direccion, Observacion,
        Giro, Nombre, Alias, Calle, NoExt, NoInt, Colonia, Cp, Manual, ManualDir,
        Cantidad, Documentacion, Regulado, ReguladoAnio, Afiliado,
        AfiliadoDescr, Telefono, Contacto,  Horario, Largo,Ancho
    } = nuevoRegistro
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {
      
        getListGiro()
        getListCP()
        getListTipo()
        getListHorario()
        if (listSecc.length === 0) {
            getListSecciones()
        }

    }, []);

    useEffect(() => {
      
        
        if (Cp.length !== 0) {
            getListColonia(Cp)
        }

    }, [Cp]);

    const getListTipo = () => {
        const data = qs.stringify({
            Usuario: idusu,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "comercio/v1/tipo";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListTipo(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListHorario = () => {
        const data = qs.stringify({
            Usuario: idusu,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "comercio/v1/horario";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListHorario(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };


    const getListGiro = () => {
        const data = qs.stringify({
            Usuario: idusu,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "comercio/v1/giro";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListGiro(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListCP = () => {
        const data = qs.stringify({
            usuario: idusu,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar/v2/cp";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListCP(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListColonia = (auxCp) => {
        const data = qs.stringify({
            usuario: idusu,      
            cp:auxCp,      
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar/v2/cp/colonia";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListColonia(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListSecciones = () => {
        const data = qs.stringify({
            usuario: idusu,municipio:138,
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar-seccion";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListSecc(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarSeccionSelect = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: e.target.value
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: ""
            })
        }
    }

    const onChangeNumTel = e =>
    {   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNuevoRegistro({ ...nuevoRegistro,[e.target.name] : e.target.value })                            
        }		    
    
    }; 

    const onChange = e => {
        setNuevoRegistro({...nuevoRegistro,[e.target.name]: e.target.value})
    }

    const onChangeCp = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Cp: e.target.value,
                Colonia: "", 
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Cp: "", Colonia: "", 
            })
        }
    }

    const onChangeEdo = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Estado: e.target.value,
                Municipio: "", Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Estado: "",
                Municipio: "", Seccion: ""
            })
        }
    }

    const setNewComercio = () => {
        setCargando(true)
       // setBotonDeshabilitado(true);

        const data = qs.stringify({
            Usuario: idusu,
            Edo: Estado,
            Muni: Municipio,
            Secc: Seccion,
            fecha: moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00',            
            Observ: Observacion,
            latitud: Latitud,
            longitud: Longitud,                                    
            Giro:Giro,
            Nombre:Nombre,
            Alias:Alias,
            Calle:Calle,
            NoExt:NoExt,
            NoInt:NoInt,
            Colonia:Colonia,
            Cp:Cp!=="" ? Cp:0,
            Manual:Manual,
            ManualDir:ManualDir,
            Cantidad:Cantidad,
            AntLat:0.0,
            AntLon:0.0,
            Documentacion:Documentacion,
            Regulado:Regulado,
            ReguladoAnio:0,
            Afiliado:Afiliado,
            AfiliadoDescr:AfiliadoDescr,
            Telefono:Telefono,
            Contacto:Contacto,
            Tipo:Tipo,
            Horario:Horario,
            Largo:Largo,
            Ancho:Ancho,
            idDispositivo: '45vf2d1vdf21d2f'
        });

        const url = "comercio/v2/new";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Comercio agregado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-Comercio${1}`
                })
                actualiza()
                setAbrirModal(false)
            } else {
               // setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-Comercio${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }

    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta(auxMensaje,"warning") 
            }
        }        
        return respuesta;
    }

    function validaDimension(auxCampo,auxMensaje) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo>0 && auxCampo<100 ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta(auxMensaje,"warning") 
            }
        }        
        return respuesta;
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
          //  console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const verificaNulos2 = (campo, auxmen)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                  
            setMensaje(auxmen);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    function guardarDatos(e) {
        e.preventDefault()
        const auxNom="Debe escribir un Nombre"
        const auxAlias="Debe escribir un Alias"
        const auxContacto="Debe escribir un Contacto"
        const auxCalle="Debe escribir una Calle"
        const auxDoc="Debe contestar a ¿Cuenta con Documentación?"
        const auxReg="Debe contestar a ¿Esta regulado en sus pagos Bimestrales?"
        const auxAfil="Debe contestar a ¿Pertenece a alguna organización Comercial?"
        const auxLargo="Debe escribir un Largo mayor a 0"
        const auxAncho="Debe escribir un Ancho mayor a 0"
        if ( !verificaNulos(Seccion,"Sección") && !verificaNulos(Tipo,"Tipo")  && !verificaNulos(Horario,"Horario")  
            && !verificaNulos(Giro,"Giro") && validaVariable(Nombre, auxNom, 3)  && validaVariable(Alias, auxAlias, 3)  
            && validaVariable(Contacto, auxContacto, 3)  && validaVariable(Calle, auxCalle, 3)  && !verificaNulos(NoExt,"NoExt")  
            && validaDimension(Largo,auxLargo) && validaDimension(Ancho,auxAncho) && validaVariable(Telefono,'Telefono',10)
            && !verificaNulos(Cp,"C.P.")  && !verificaNulos(Colonia,"Colonia") && !verificaNulos2(Documentacion,auxDoc)  
            && !verificaNulos2(Regulado,auxReg)  && !verificaNulos2(Afiliado,auxAfil)  
            //&& !verificaNulos2(Afiliado,auxAfil)  
        ) 
        {   //console.log("prueba",nuevoRegistro);
            setAviso(false);
            setNewComercio()
           // console.log( moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00');
        }  
    }
    
    const onChangeRad=(e)=>{
        setNuevoRegistro({...nuevoRegistro,[e.target.name] : e.target.value,  })                           
    }

    return (
    <Modal
        titulo={'Nuevo Registro'} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'md'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        
        <Box display="flex" flexDirection="column" >        
        
        <Box display={"flex"} flexDirection={"row"}>
        <Box  mr={1}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture label="Fecha"
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate="2017-03-14" maxDate={new Date()}
                        value={selectedDate} onChange={handleDateChange}
                        style={{width: 6.5 + 'rem'}}
                    />
                </MuiPickersUtilsProvider>
            </Box>
        {listSecc.length !== 0 ?            
            <Box mr={1} >                
                <FormControl size='small' style={{}}>
                    <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                    <Select size='small' variant="outlined" style={{width: '6.2rem'}}
                        label="Sección" id="Seccion-select2" name="Seccion"
                        value={Seccion} onChange={guardarSeccionSelect}
                        fullWidth
                    >
                        {listSecc.map((seccion, index) => {
                            return (
                            <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                <em>{seccion.Numero}</em>
                            </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>                
            </Box>
            :   null
        }
          
            {listTipo.length !== 0 ?
              <Box mr={1}>
                <FormControl size="small">
                    <InputLabel htmlFor="TipoInp" size="small" variant="outlined" >Tipo</InputLabel>
                    <Select   value={Tipo} fullWidth onChange={onChange} style={{width:"8rem"}}
                        name='Tipo' id='TipoSlct' size="small" variant="outlined" 
                    >
                        {listTipo.map(element => {
                            if (element.Id!==0) {
                                return(
                                <MenuItem size='small' value={element.Id} key={element.Id}>
                                    <em>{element.Nom}</em>
                                </MenuItem>)                                
                            }
                            
                        })}
                    </Select>
                </FormControl>
                </Box>
                : null
            }            
            {listHorario.length !== 0 ?
            <Box  >                        
                <FormControl size="small">
                    <InputLabel htmlFor="HorarioInp" size="small" variant="outlined" >Horario</InputLabel>
                    <Select   value={Horario} fullWidth onChange={onChange} style={{minWidth:"6rem"}}
                        name='Horario' id='HorarioSlct' size="small" variant="outlined" 
                    >
                        {listHorario.map(element => {
                            if (element.Id!==0) {
                                return(
                                <MenuItem size='small' value={element.Id} key={element.Id}>
                                    <em>{element.Nom}</em>
                                </MenuItem>)         
                            }                   
                        })}
                    </Select>
                </FormControl>
                </Box>
                : null
            }
            </Box>
            <Box  mt={2}>
            
            {listGiro.length !== 0 ?
                <FormControl size="small">
                    <InputLabel htmlFor="GiroInp" size="small" variant="outlined" >Giro</InputLabel>
                    <Select   value={Giro} fullWidth onChange={onChange} style={{minWidth:"20rem"}}
                        name='Giro' id='GiroSlct' size="small" variant="outlined" 
                    >
                        {listGiro.map(element => {
                            return(
                            <MenuItem size='small' value={element.Id} key={element.Id}>
                                <em>{element.Nom}</em>
                            </MenuItem>)                            
                        })}
                    </Select>
                </FormControl>
                : null
            }
            </Box>
            
           <Box display={"flex"} flexDirection={"row"}>
                <Box style={{marginBottom:.5+'rem',marginTop:.5+'rem'}} mr={1}>
                    <TextField id="NombreTxt" name="Nombre" label="Nombre" fullWidth size="small"
                        value={Nombre} onChange={onChange} variant="outlined"/>
                </Box>
                <Box  style={{marginBottom:.5+'rem',marginTop:.5+'rem'}} mr={1}>
                    <TextField id="AliasTxt" name="Alias" label="Alias" fullWidth size="small"
                        value={Alias} onChange={onChange} variant="outlined"/>
                </Box>
        
            </Box>
            <Box  style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="ContactoTxt" name="Contacto" label="Contacto" fullWidth size="small"
                    value={Contacto} onChange={onChange} variant="outlined"/>
            </Box>
            <Box style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="CalleTxt" name="Calle" label="Calle" fullWidth size="small"
                    value={Calle} onChange={onChange} variant="outlined"/>
            </Box>
            <Box display={"flex"} flexDirection={"row"}>
                <Box style={{marginBottom:.5+'rem',marginTop:.5+'rem',width:"5rem"}} mr={1}>
                    <TextField id="NoExtTxt" name="NoExt" label="NoExt" fullWidth size="small"
                        value={NoExt} onChange={onChangeNumTel} variant="outlined"/>
                </Box>
                <Box  style={{marginBottom:.5+'rem',marginTop:.5+'rem', width:"5rem"}} mr={1}>
                    <TextField id="NoIntTxt" name="NoInt" label="NoInt" fullWidth size="small"
                        value={NoInt} onChange={onChange} variant="outlined"/>
                </Box>
                <Box  style={{marginBottom:.5+'rem',marginTop:.5+'rem', width:"6rem",marginRight:".5rem"}}>
                    <TextField id="Largo" name="Largo" label="Largo" fullWidth size="small"
                        value={Largo} onChange={onChangeNumTel} variant="outlined"/>
                </Box>
                <Box  style={{marginBottom:.5+'rem',marginTop:.5+'rem', width:"6rem", marginRight:".5rem"}}>
                    <TextField id="Ancho" name="Ancho" label="Ancho" fullWidth size="small"
                        value={Ancho} onChange={onChangeNumTel} variant="outlined"/>
                </Box>
                <Box   style={{marginBottom:.5+'rem',marginTop:.5+'rem', width:"8rem"}}>
                    <TextField id="TelefonoD" name="Telefono" label="Telefono"size="small"
                        inputProps= {{ maxlength: 10 }} 
                        value={Telefono} onChange={onChangeNumTel} variant="outlined"/>
                </Box>

            </Box>
            <Box display={"flex"} flexDirection={"row"}>
                {listCP.length !== 0 ?
                    <Box  style={{marginBottom:.5+'rem',marginTop:.5+'rem'}} mr={1}>
                    <FormControl size="small">
                        <InputLabel htmlFor="CpInp" size="small" variant="outlined" >C.P.</InputLabel>
                        <Select   value={Cp} fullWidth onChange={onChange} style={{minWidth:"7rem"}}
                            name='Cp' id='CpSlct' size="small" variant="outlined" 
                        >
                            {listCP.map(element => {
                                return(
                                <MenuItem size='small' value={element.Cp} key={element.Cp}>
                                    <em>{element.Cp}</em>
                                </MenuItem>)                            
                            })}
                        </Select>
                    </FormControl>
                    </Box>
                : null
                }
                {listColonia.length !== 0 ?
                <Box   style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <FormControl size="small">
                    <InputLabel htmlFor="ColoniaInp" size="small" variant="outlined" >Colonia</InputLabel>
                    <Select   value={Colonia} fullWidth onChange={onChange} style={{minWidth:"12rem"}}
                        name='Colonia' id='ColoniaSlct' size="small" variant="outlined" 
                    >
                        {listColonia.map(element => {
                            return(
                            <MenuItem size='small' value={element.Col} key={element.Col}>
                                <em>{element.Col}</em>
                            </MenuItem>)                            
                        })}
                    </Select>
                </FormControl>
                </Box>
                : null
                }     
            </Box>                        
          
        
            <Box mb={1}  >
                <TextField
                    id="Observacion" name="Observacion"
                    label="Observación" variant="outlined"
                    multiline fullWidth onChange={onChange}
                    maxRows={2} value={Observacion}
                />
            </Box>
            <FormControl component="fieldset" style={{marginLeft:.5+'rem' }}>          
                    <FormLabel  >¿Cuenta con Documentación?</FormLabel>        
                    <RadioGroup  row aria-label="position"  defaultValue="top"
                        name="Documentacion" value={Documentacion} onChange={onChangeRad}
                    >                        
                        <FormControlLabel value={"0"}  label={"No"}
                            style={{}}
                            control={<Radio color="primary" />} />
                        <FormControlLabel value={"1"}  label={"Si"}
                            style={{}}
                            control={<Radio color="primary" />} />                                                                      
                    </RadioGroup>                     
                </FormControl>  
            <FormControl component="fieldset" style={{marginLeft:.5+'rem' }}>          
                <FormLabel  >¿Esta regulado en sus pagos Bimestrales?</FormLabel>        
                <RadioGroup  row aria-label="position"  defaultValue="top"
                    name="Regulado" value={Regulado} onChange={onChangeRad}
                >                        
                    <FormControlLabel value={"0"}  label={"No"}
                        style={{}}
                        control={<Radio color="primary" />} />
                    <FormControlLabel value={"1"}  label={"Si"}
                        style={{}}
                        control={<Radio color="primary" />} />                                                                      
                </RadioGroup>                     
            </FormControl>  
            <FormControl component="fieldset" style={{marginLeft:.5+'rem' }}>          
                <FormLabel  >¿Pertenece a alguna organización Comercial?</FormLabel>        
                <RadioGroup  row aria-label="position"  defaultValue="top"
                    name="Afiliado" value={Afiliado} onChange={onChangeRad}
                >                        
                    <FormControlLabel value={"0"}  label={"No"}
                        style={{}}
                        control={<Radio color="primary" />} />
                    <FormControlLabel value={"1"}  label={"Si"}
                        style={{}}
                        control={<Radio color="primary" />} />                                                                      
                </RadioGroup>                     
            </FormControl>  
            {Afiliado==="1" ?
                <Box   style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="AfiliadoDescrTxt" name="AfiliadoDescr" label="Organización " fullWidth size="small"
                    value={AfiliadoDescr} onChange={onChange} variant="outlined"/>
                </Box>
                :null
            }
        </Box>
    </Modal>);
};

export default NvoComercio;
