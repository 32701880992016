import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    estiloCard:{
        width: '33rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        paddingBottom:'.5rem',
        paddingLeft:'.5rem',
        paddingRight:'.5rem',
        maxHeight:'100%',
        minHeight: "11rem",
        marginRight:'1rem'
    },  
    tablaContainer: {
        width: '32rem',
        maxHeight: "26rem",
        minHeight: "8rem",
        //minWidth:'100%',
      }, 
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const columnas=[                    
    { id:1, titulo:"Usuario", alinear:'center' },      
    { id:2, titulo:"Tipo", alinear:'center' },         
    { id:3, titulo:"Dirección", alinear:'center' },          
    { id:4, titulo:"Observación", alinear:'center' },                  
  ];
 
export default function TablaSeccRecorrido({listMapRecor, puente, setPuente }){    
  const classes = useStyles();    
  const [registro, setRegistro] = useState(null)
  const [datos,setDatos] = useState([]);     
  const auxCol=process.env.REACT_APP_Fondo_Color
  
  useEffect(()=>{
    setDatos(listMapRecor) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listMapRecor])          

  const onChangeTable =(regis)=>{                       
    setPuente(regis)
  } 

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
        padding={'none'} style={estilo} >
        {contenido}
    </TableCell>)
  }

  const obtnEtiqRecor=(auxRec)=>{
    const arrRec=[0,1,2,3,1,2,3,1,2,3]
    let respuesta="https://benitojuarez.sfo3.cdn.digitaloceanspaces.com/recurso/marker"
    respuesta=`${respuesta}${arrRec[auxRec.r1Id]}${arrRec[auxRec.r2Id]}${arrRec[auxRec.r3Id]}.svg`    
    return(respuesta)
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>          
            <CelTitl index={1} titulo={""} aline={""} /> 
            {columnas.map((columna,index)=>              
              <CelTitl index={index+2} titulo={columna.titulo} aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody>         
        {auxDatos.map((elemnto,index) => {  
          const auxVal=puente?.Id === elemnto?.Id ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index+1} onClick={ () => onChangeTable(elemnto) }  
          > 
            {celda("center", {width:"2rem",fontSize:"11px"},               
              <img src={obtnEtiqRecor(elemnto)} style={{width:"20px",height:"20px"}}/>
              ,index+1) }           
            {celda("left", {width:"4rem",fontSize:"11px"}, elemnto.UsrNom,index+1) }
            {celda("center", {width:"1.8rem",fontSize:"11px"}, elemnto.TipoNom,index+2) }                
            {celda("left", {width:"5.7rem",fontSize:"11px"}, elemnto.Dir,index+4) }    
            {celda("left", {width:"4.3rem",fontSize:"11px",paddingLeft:".4rem"}, elemnto.Obsrv,index+5) }            
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
                   
  const llamada=  datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                    <Typography variant="h5" component="h5">No contiene datos</Typography>
                                    </Box>) 
  return (
  <Box display={'flex'} flexDirection="row"style={{height: "26rem"}}> 
    <Card className={classes.estiloCard} style={{height:"26rem"}}>                                   
      {  llamada }   
    </Card>   
  </Box>                   
  );
}