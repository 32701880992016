import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import {GeneralesContext} from '../context/GeneralesContext'
import { Box, Button, Card, CardContent, Grid,
    IconButton, LinearProgress, Paper, Tooltip,
    Typography,} from "@material-ui/core";
import TablaComercios from "./TablaComercios";
import Relojes from "./Relojes";
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";
import {AddCircleOutlined} from "@material-ui/icons";
import ModalNvoComercio from "./NvoComercio";
import ModalAgregarEvidencia from "./ModalAgregarEvidencia";

const useStyles = makeStyles((theme) => ({}));

const Comercio = () => {
    const classes = useStyles();
    const idusu = localStorage.getItem("UsuId");
    const history = useHistory();
    const source = axios.CancelToken.source();

    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({
        inicio: new Date(),
        final: new Date(),
    });
    const {generales, guardarGenerales} = useContext(GeneralesContext);
    const [selectedID, setSelectedID] = useState(null);
    const [recorrido, setRecorrido] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [latitud, setLatitud] = useState(0);
    const [longitud, setLongitud] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [abrirModalMapa, setAbrirModalMapa] = useState(false);
    const [abrirModalAgregar, setAbrirModalAgregar] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [buscar, setBuscar] = useState(true);
    const [checked, setChecked] = useState(false);
    const [abrirModal, setAbrirModal] = useState(false);
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitud(position.coords.latitude);
                setLongitud(position.coords.longitude);
            });
        } else {
           // console.log("Not Available");
            setLatitud(0);
            setLongitud(0);
        }
        guardarGenerales({...generales, Titulo: 'Comercio'})
    }, []);


    useEffect(() => {
        setGaleria([]);

        const consultarImagenes = () => {
            let data = qs.stringify({
                Llave: selectedID,
                Usuario: idusu,
                idDispositivo: "987gyubjhkkjh",
            });
            let url = "comercio/v1/file/list";

            function respuesta(response) {
                if (response[0].Id === -1) {
                    setGaleria([]);
                    setMensaje("Sin fotografías.");
                } else {
                    setGaleria(response);
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
        };
        if (selectedID !== null) {
            consultarImagenes();
        }
    }, [selectedID,update]);

    const buscarDatos = () => {
        setBuscar(!buscar)
    }

    const modalAgregarEvidencia = abrirModal ? <ModalAgregarEvidencia abrirModal={abrirModal}
                                                setAbrirModal={setAbrirModal} recorrido={recorrido}
                                                selectedID={selectedID} idusu={idusu}  setUpdate={setUpdate}
                                            /> : null

    const modalAgregarComercio = abrirModalAgregar ? <ModalNvoComercio abrirModal={abrirModalAgregar}
                                                        setAbrirModal={setAbrirModalAgregar}
                                                        idusu={idusu} history={history}
                                                        source={source} latitud={latitud}
                                                        longitud={longitud} actualiza={buscarDatos}
                                                    /> : null

    const agregarDatos = () => {
        setAbrirModalAgregar(true)
    }

    const listarGaleria =
        galeria.length > 0 ? (
            <Galeria
                galeria={galeria}
            />
        ) : (
            <Paper className={classes.root}>
                <Typography variant={"h5"}>{mensaje}</Typography>
            </Paper>
        );

    const handleChange = () => {
        setChecked(!checked)
    };

    const handleAbrir = () => {
        setAbrirModal(true)
    }

    const principal = () => {
        return (
        <Grid container spacing={1}>
            {modalAgregarComercio}
            <Grid item xs={12}>
                <Card>
                    <CardContent style={{padding:0.4+'rem'}} >
                        <Grid container spacing={1} alignItems={'center'}>
                            <Grid item>
                                <Tooltip title={'Buscar'}>
                                    <IconButton color="primary" aria-label="Buscar" onClick={buscarDatos} >
                                        <SearchIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Relojes fechasSeleccionadas={fechasSeleccionadas}
                                    setFechasSeleccionadas={setFechasSeleccionadas}
                                />
                            </Grid>
                            {/* <Grid item>
                                <FormControlLabel value={checked}
                                    control={<Checkbox color="primary"/>}
                                    label="Buscar solo mis registros" labelPlacement="end"
                                    size={'small'} onChange={handleChange}
                                />
                            </Grid> */}
                            <Grid item>
                            <Tooltip title={'Agregar Comercio'}>
                                    <IconButton color="primary" aria-label="Agregar Comercio" onClick={agregarDatos} >
                                        <AddCircleOutlined/>
                                    </IconButton>
                                </Tooltip> 
                            </Grid>
                                
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8}>
                <TablaComercios
                    history={history} setRecorrido={setRecorrido}
                    fechasSeleccionadas={fechasSeleccionadas}
                    selectedID={selectedID} setSelectedID={setSelectedID}
                    source={source} idusu={idusu} buscar={buscar}
                    checked={checked} abrirModal={abrirModalMapa}
                    setAbrirModal={setAbrirModalMapa}
                />
            </Grid>
            <Grid item xs={4}>
                {modalAgregarEvidencia}
                {recorrido.Obsrv ? 
                    cargando ? (
                        <Box mt={2} mb={2} width="80%">
                            <LinearProgress/>
                        </Box>
                    ) 
                    : (
                        <Grid container>
                        <Grid item xs={12} style={{paddingBottom: 10}}>
                            <Typography color="primary">
                                Observación
                            </Typography>
                            <Typography>
                                {recorrido?.Obsrv}
                            </Typography>
                        </Grid>
                        </Grid>
                    ) 
                :null}
                {selectedID !== null ?
                 <Grid container>
                    <Grid item xs={12} style={{paddingBottom: 10}}>
                        {/* <Button
                            variant="contained" color="primary"
                            startIcon={<AddCircleOutlined/>}
                            onClick={handleAbrir}
                        >
                            Subir Fotos
                        </Button> */}
                    </Grid>
                </Grid> 
                : null}
                {cargando ? 
                    <Box pl={1} pt={5}>
                        <LinearProgress/>
                    </Box>
                : listarGaleria}
            </Grid>
        </Grid>
        );
    };

    return <div>{principal()}</div>;
};

export default Comercio;
